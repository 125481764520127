import { z } from 'zod';

export function syncFormFieldValue(fieldList: (z.infer<typeof FieldInfo>), field: (z.infer<typeof FieldInfoItem>), defaultValue: string | Date | undefined): string | Date | null | undefined {
    let matchingItems: (z.infer<typeof FieldInfoItem>)[] = [];
    if (isOrganizationLookupById(field.id)) {
        fieldList.forEach(_ => {
            // Check for company fields
            if (isOrganizationLookupById(_.id)) {
                if (_.id === field.id ){
                    _.dateLastChange = new Date();
                }
                matchingItems.push(_);
            }
        });
    } else {
        fieldList.forEach(_ => {
            // Check if at least one element in field.entityMappings is present in _.entityMappings
            if (_.entityMappings && _.entityMappings
                .some(r => field.entityMappings
                    .some(f => (f.entity !== '' && f.attribute !== '') &&
                        (f.entity === r.entity && f.attribute === r.attribute))
            )) {
                matchingItems.push(_);
            }
        });
    }
    // Add the original field to the matchingItems array (not needed, the original field also matches above)
    // matchingItems.push(field);

    // Sort the matchingItems array by date from most recent
    matchingItems.sort((a, b) => (b.dateLastChange ?? new Date(1900, 1, 1)).getTime() - (a.dateLastChange ?? new Date(1900, 1, 1)).getTime());

    // If there are matching items, return the value of the most recent one
    if (matchingItems.length > 0) {
        const mostRecentItem = matchingItems[0];
        if (matchingItems.length === 1) {
            mostRecentItem.value = defaultValue;
        }

        // ToDo: The lookup should set this value correctly as json `{ Value: accountId , Text: companyName }`
        // if (fieldIsCustomerOrganization(mostRecentItem.id)) {
        //     try {
        //         const accountId = crmContactDictionary['account.id'];
        //         const companyName = crmContactDictionary['account.companyName'];
        //         return JSON.stringify({ Value: accountId , Text: companyName ?? ''  });
        //     } catch (error) {
        //         // handle the error
        //     }
        // }

        // Set all matchingItems to the value of the most recent item
        matchingItems.forEach(_ => {
            _.value = mostRecentItem.value;
        });

        matchingItems.filter(_ => _.id != field.id).forEach(_ => {
            _.value = defaultValue;
        });
        
        if (mostRecentItem.value === Guid.Empty){
            mostRecentItem.value = '';
        }

        return mostRecentItem.value;
    }

    // If no matching items, return the default value
    return defaultValue ?? '';
}

// Should probably pass sectionName and set sectionFormFields by filtering allFormFields
export function SetFormValues(allFormFields: (z.infer<typeof FieldInfo>), sectionFormFields: (z.infer<typeof FieldInfo>), HasChanges: Boolean) {
    // Could HasChanges be called as a function from here?
    if (HasChanges) {

        sectionFormFields.forEach(field => {
            //Add the Registration Option
            var foundField = allFormFields.find(_ => _.id === field.id);
            if (foundField) {
                foundField.value = syncFormFieldValue(allFormFields, field, field.value);
            }
            else {
                allFormFields.push(field);
            }
        });
    }
}

export function syncValidationValue(fieldList: (z.infer<typeof FieldInfo>), field: (z.infer<typeof FieldInfoItem>), defaultValue: string | Date | undefined): string | Date | undefined {
    let matchingItems: (z.infer<typeof FieldInfoItem>)[] = [];

    fieldList.forEach(_ => {
        // Check if at least one element in field.entityMappings is present in _.entityMappings
        if (_.entityMappings
            .some(r => field.entityMappings
                .some(f => f.entity === r.entity && f.attribute === r.attribute
            )
        )) {
            matchingItems.push(_);
        }
    });

    // Add the original field to the matchingItems array (not needed, the original field also matches above)
    // matchingItems.push(field);

    // Sort the matchingItems array by date from most recent
    matchingItems.sort((a, b) => (b.dateLastChange ?? new Date(1900, 1, 1)).getTime() - (a.dateLastChange ?? new Date(1900, 1, 1)).getTime());

    // If there are matching items, return the value of the most recent one
    if (matchingItems.length > 0) {
        const mostRecentItem = matchingItems[0];
        if (matchingItems.length === 1) {
            mostRecentItem.value = defaultValue;
        }

        // ToDo: The lookup should set this value correctly as json `{ Value: accountId , Text: companyName }`
        // if (fieldIsCustomerOrganization(mostRecentItem.id)) {
        //     try {
        //         const accountId = crmContactDictionary['account.id'];
        //         const companyName = crmContactDictionary['account.companyName'];
        //         return JSON.stringify({ Value: accountId , Text: companyName ?? ''  });
        //     } catch (error) {
        //         // handle the error
        //     }
        // }

        // Set all matchingItems to the value of the most recent item
        matchingItems.forEach(_ => {
            _.value = mostRecentItem.value;
        });

        return mostRecentItem.value;
    }

    // If no matching items, return the default value
    return defaultValue ?? '';
}

export function fieldIsCustomerOrganization(fieldId: string): boolean {
    let isCustomerOrganizationField = (fieldId.startsWith('parentcustomerid')
    || fieldId.startsWith('new_accountdebtor')
    || fieldId.endsWith('brite_04_08_organizationcompany')
    || fieldId.endsWith('brite_05_03_organization'));

    return isCustomerOrganizationField;
}

export function minInputLength(item: z.infer<typeof FieldInfoItem>) {
    if (isOrganizationLookup(item))
    {
        return 3; 
    }
    else {
        return 0;
    }
}

export function isOrganizationLookupById(itemId: string) {
    if  ((itemId.endsWith("04_08_organizationcompany"))
        || (itemId.endsWith("05_03_organization"))
        || (itemId.endsWith("07_05_company")))
    {
        return true;
    }
    else {
        return false;
    }
}

export function isOrganizationLookup(item: z.infer<typeof FieldInfoItem>) {
    return isOrganizationLookupById(item.id);
}

export function isDivisionLookup(item: z.infer<typeof FieldInfoItem>) {
    if  (item.id.endsWith("05_04_divisionasontheinvoice"))
    {
        return true;
    }
    else {
        return false;
    }
}

export function getColour(colour: string) {
    let newColour: string = colour.replace("text-", "");
    newColour = (newColour === "")? "steelblue" : newColour;
    return newColour;
}

export function mapInterestAreaButton(item: any) {
    let buttonSpec: IControlButton = {
        buttonElName: item.id,
        buttonText: item.itemText,
        class: getColour(item.colour),
        enableUrl: false,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/",
        type: item.colour
    }
    return buttonSpec;
}

export async function getEntityFormFieldList(entity: string, sectionDescription: string) {
    const allFormFieldDefinitions: FormFieldDefinition[] = await fetchJsonData('forms/form-fields') as unknown as FormFieldDefinition[];
    const entityFields = allFormFieldDefinitions.filter(field => field.Entity === entity && field.Section === (sectionDescription??field.Section));

    return entityFields;
}

export async function addEntityFormFieldsAsync(entity: string, sectionDescription: string, newFormFields: (z.infer<typeof FieldInfo>)) {
    const modalFormFieldDefinition: FormFieldDefinition[] = await getEntityFormFieldList(entity, sectionDescription);
    let hiddenFieldOrder = 10000;
    modalFormFieldDefinition.forEach((formFieldDefinition) => {
        let field = newFormFields.find(_ => _.id === formFieldDefinition.Id);
        if (!field) {
            // Create hidden field
            console.log('Create hidden modal field: ',
                formFieldDefinition.Entity.toLowerCase(),
                formFieldDefinition.AttributeName.toLowerCase());

            newFormFields.push({
                id: formFieldDefinition.Id,
                entityMappings: [{"entity": "","attribute": ""}],
                type: 'string',
                order: hiddenFieldOrder,
                labelText: '',
                value: '',
                isShown : false,
                section: sectionDescription,
                isHidden: true,
            });
            hiddenFieldOrder = hiddenFieldOrder + 1;
        }
    });
}