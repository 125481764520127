<template>
    <label class="gibs-toggle-button-label">
        <input type="checkbox" value="" class="sr-only peer" @click="toggleOutput()" :checked="toggle">
        <div class="gibs-toggle-button peer peer-checked:after:translate-x-[20px] peer-checked:bg-catalinablue"></div>
        <span class="ms-3 text-base">Select all</span>
    </label>
</template>

<script setup lang="ts">
    const props = defineProps({
        toggleChecked: {
            type: Boolean,
            default: false
        }
    })
    const emit = defineEmits(["toggleEmit"]);
    const toggle = ref<boolean>(false);

    function toggleOutput() {
        toggle.value = !toggle.value;
        emit("toggleEmit", toggle.value);
    }

    function setupToggle() {
        if (props.toggleChecked === true) {
            toggle.value = true;
        } else {
            toggle.value = false;
        }
    }

    watch(() => props.toggleChecked, () => {
        setupToggle();
    })

    onMounted(() => {
        setupToggle();
    })
</script>