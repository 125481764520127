<template>
    <button @click="clickButton(props.buttonSpec)" type="button" :id="props.buttonSpec.buttonElName" class="gibs-interest-area-ghost-button"
        :class="props.buttonSpec.class">
        <span>{{ props.buttonSpec.buttonText }}</span>
        <NuxtIcon name="buttons/plus" class="plus-icon"/>
        <NuxtIcon name="buttons/tick" class="tick-icon"/>
        <NuxtIcon name="buttons/close-cross" class="cross-icon"/>
    </button>
</template>
  
<script setup lang="ts">

    const props = defineProps({
        buttonSpec: {
            type: Object,
            default: buttonObject
        },
        selected: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(["interestAreaEmit"]);

    function clickButton(button: IControlButton) {
        const id = button.buttonElName;
        const itemText = button.buttonText;
        const buttonElement = document.getElementById(props.buttonSpec.buttonElName);
        if (buttonElement) {
            buttonElement.classList.toggle("selected");
            if (buttonElement.classList.contains("selected")) {
                emit("interestAreaEmit", {id: id, itemText: itemText, selected: true});
            } else {
                emit("interestAreaEmit", {id: id, itemText: itemText, selected: false});
            }
        }
    }

    watch(() => props.selected, () => {
        const buttonElement = document.getElementById(props.buttonSpec.buttonElName);
        if (props.selected === true && !buttonElement?.classList.contains("selected")) {
            buttonElement!.classList.add("selected");
        } else if (props.selected === false && buttonElement?.classList.contains("selected")) {
            buttonElement!.classList.remove("selected");
        }
    })

    onMounted(() => {
        if (props.selected === true) {
            const buttonElement = document.getElementById(props.buttonSpec.buttonElName);
            if (props.selected === true && !buttonElement?.classList.contains("selected")) {
                buttonElement!.classList.add("selected");
            }
        }
    })
</script>

<script lang="ts">
    var buttonObject: IControlButton = {
        buttonElName: "interest-area-ghost-button",
        buttonText: "Find out more",
        class: "",
        enableUrl: false,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/"
    }
</script>